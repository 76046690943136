
<template>
  <div class="appbox">
        <top_nav>导航栏（全局组件）</top_nav>
        
   <!-- 正文内容 -->
        <div class="contentHome">
          <!-- <keep-alive> -->
            <router-view></router-view>
          <keep-alive> </keep-alive>
        </div>

      <!-- 网站底部 -->
      <bottom></bottom>
  </div>
  
</template>

<script>


export default {
  name: "App",
  data() {
    return {};
  },
 
};
</script>

<style>
/* 项目css初始化 */
* {
  padding: 0;
  margin: 0;
  /*去掉li的小圆点*/
  list-style: none;
   /*去掉a的下划线*/
  text-decoration: none;
  /* 盒子大小固定 */
  box-sizing: border-box;
  font-family: '微软雅黑';
  font-size: 14px;
  /* color: #666666; */
}


/* 页面背景 */
.appbox {
 background: #fff;
 
 
}





</style>